import React, { useState, useEffect } from "react";

// antd
import { Radio, Form } from "antd";

// LOCALIZATION
import LOCALIZATION from "@timetrack/common/src/services/LocalizationService";

function RadioButton({
  name,
  label,
  defaultValue,
  onChange, // onChange passed from parent
  disabled,
  notwrapInForm,
  options,
  required,
  validator,
  validateTrigger,
  size,
  optionType,
  buttonStyle
}) {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  // Handle the internal state and also notify the parent about the change
  const handleChange = ({ target: { value } }) => {
    setValue(value); // Update the internal state
    if (!!onChange) {
      onChange(value); // Notify the parent about the change
    }
  };

  const radioButton = (
    <Radio.Group
      onChange={handleChange} // Use the internal handler
      value={value}
      disabled={disabled}
      size={size || "large"}
      optionType={optionType || "button"}
      options={options}
      buttonStyle={buttonStyle || "solid"}
      defaultValue={defaultValue}
    />
  );

  let rules = [{ required: required, message: LOCALIZATION.REQUIRED }];

  if (validator) {
    rules.push(validator);
  }

  return notwrapInForm ? (
    <>{radioButton}</>
  ) : (
    <Form.Item
      label={label}
      name={name}
      rules={rules}
      validateTrigger={validateTrigger || "onBlur"}
    >
      {radioButton}
    </Form.Item>
  );
}

export default RadioButton;
