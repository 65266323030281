import { SECTIONS } from "./MenuSections";

const { CLIENT, PROJECT, EMPLOYEE, PROJECTS, INVOICING, STATUSBOARD, LOGOUT, SKILL_MATRIX, AWS_SERVERS, DISCOVERY_SESSION, FEEDBACK } = SECTIONS;

const URL = {
    // Dashbaord
    DASHBOARD: "/dashboard",

    // Client
    LIST_CLIENT: `/${CLIENT}s/list`,
    VIEW_CLIENT: `/${CLIENT}/view/:id`,
    ADD_CLIENT: `/${CLIENT}/add`,
    EDIT_CLIENT: `/${CLIENT}/edit/:id`,
    DETAILS_CLIENT: `/${CLIENT}/details/:id`,
    ADD_RATES_CARD_CLIENT: `/${CLIENT}/ratesCard/add/:id`,
    EDIT_RATES_CARD_CLIENT: `/${CLIENT}/ratesCard/edit/:id`,

    // Feedback
    LIST_FEEDBACK: `/${FEEDBACK}s/list`,
    VIEW_FEEDBACK: `/${FEEDBACK}/view/:id`,
    ADD_FEEDBACK: `/${FEEDBACK}/add`,
    EDIT_FEEDBACK: `/${FEEDBACK}/edit/:id`,
    DETAILS_FEEDBACK: `/${FEEDBACK}/details/:id`,

    // Status Board
    STATUSBOARD: `/${STATUSBOARD}`,

    // Project
    LIST_PROJECT: `/${PROJECTS}/list`,
    ADD_PROJECT: `/${PROJECT}/add`,
    EDIT_PROJECT: `/${PROJECT}/edit/:id`,
    DETAILS_PROJECT: `/${PROJECT}/details/:id`,
    SPRINT_SESSIONS_PROJECT: `/${PROJECT}/details/sprintSessions/:id`,
    COMMENTS_PROJECT: `/${PROJECT}/comments/details/:id`,

    // Employee
    LIST_EMPLOYEE: `/${EMPLOYEE}/list`,
    ADD_EMPLOYEE: `/${EMPLOYEE}/add`,
    EDIT_EMPLOYEE: `/${EMPLOYEE}/edit/:id`,
    DETAILS_EMPLOYEE: `/${EMPLOYEE}/details/:id`,

    // Skill Matrix
    SKILL_MATRIX: `/${SKILL_MATRIX}/list`,

    // Invoicing
    GENERATE_INVOICING: "/invoicing",
    INVOICING_PROJECT_SCREEN: `/${INVOICING}/listProjects/:id`,
    INVOICING_PROJECT_TASKS_SCREEN: `/${INVOICING}/listProjects/tasks/:id`,
    INVOICING_PROJECT_PREPARE_INVOICE_SCREEN: `/${INVOICING}/listProjects/prepareinvoice/:id`,
    INVOICING_PROJECT_PREPARE_INVOICE_DETAILS_SCREEN: `/${INVOICING}/listProjects/prepareinvoice/details/:id`,
    INVOICING_PROJECT_TASKS_DETAILS_SCREEN: `/${INVOICING}/listProjects/tasks/details/:id`,
    INVOICING_EMPLOYEE_SCREEN: `/${INVOICING}/listEmployees/:id`,

    // AWS
    LIST_AWS_SERVERS: `/${AWS_SERVERS}/list`,

    // Discovery Session
    LIST_DISCOVERY_SESSION: `/${DISCOVERY_SESSION}/list`,
    DISCOVERY_SESSION_STEP_1_ADD: `/${DISCOVERY_SESSION}/step1/add`,
    DISCOVERY_SESSION_STEP_2_ADD: `/${DISCOVERY_SESSION}/step2/add`,
    DISCOVERY_SESSION_STEP_3_ADD: `/${DISCOVERY_SESSION}/step3/add`,
    DISCOVERY_SESSION_STEP_4_ADD: `/${DISCOVERY_SESSION}/step4/add`,
    DISCOVERY_SESSION_STEP_1_EDIT: `/${DISCOVERY_SESSION}/step1/edit/:id`,
    DISCOVERY_SESSION_STEP_2_EDIT: `/${DISCOVERY_SESSION}/step2/edit/:id`,
    DISCOVERY_SESSION_STEP_3_EDIT: `/${DISCOVERY_SESSION}/step3/edit/:id`,
    DISCOVERY_SESSION_STEP_4_EDIT: `/${DISCOVERY_SESSION}/step4/edit/:id`,
    DISCOVERY_SESSION_STEP_5_LIST: `/${DISCOVERY_SESSION}/step5/list/:id`,
    DISCOVERY_SESSION_STEP_6_LIST: `/${DISCOVERY_SESSION}/step6/list/:id`,
    DISCOVERY_SESSION_STEP_7_REVIEW: `/${DISCOVERY_SESSION}/step7/review/:id`,

    // Logout Screen
    LOGOUT: `/${LOGOUT}`,

    // auth login
    AUTH: {
        LOGIN: "/login",
        RESET_PASSWORD: "/resetPassword",
        CONFIRM_PASSWORD: "/ConfirmPassword/:uid/:token",
    },
    ERROR: {
        PAGE404: "/page404",
        PAGE500: "/page500",
    },
};

export default URL;
