/*
    General Helper Class
*/

// example: https://bithacker.dev/javascript-object-multi-property-sort

import React from 'react';

import {
  format,
  addDays,
  subDays,
  subMonths,
  subQuarters,
  subYears
} from 'date-fns'

// Date Format
import { DATE_FORMAT } from "../constants/DateFormat";

// LOCALIZATION
import LOCALIZATION from '../services/LocalizationService';

export const getReportDate = (value, DUR) => {

  const today = format(new Date(), DATE_FORMAT.YEAR_MONTH_DAY);
  const lastWeek = format(subDays(new Date(), 6), DATE_FORMAT.YEAR_MONTH_DAY);
  const lastMonth = format(addDays(subMonths(new Date(), 1), 1), DATE_FORMAT.YEAR_MONTH_DAY);
  const lastQuarter = format(addDays(subQuarters(new Date(), 1), 1), DATE_FORMAT.YEAR_MONTH_DAY);
  const lastYear = format(addDays(subYears(new Date(), 1), 1), DATE_FORMAT.YEAR_MONTH_DAY);

  switch (value) {

    case DUR.WEEK:
      return {
        start_date: lastWeek,
        end_date: today
      };
    case DUR.MONTH:
      return {
        start_date: lastMonth,
        end_date: today
      };
    case DUR.QUARTER:
      return {
        start_date: lastQuarter,
        end_date: today
      };
    case DUR.YEAR:
      return {
        start_date: lastYear,
        end_date: today
      };
    default:
      return {
        start_date: lastWeek,
        end_date: today
      };
  }
}

export const formatDate = (date = new Date, formate = DATE_FORMAT.DAY_SLASH_MONTH_SLASH_YEAR) => {
  return format(new Date(date), formate);
}

export const customFormatDate = (date, formate = DATE_FORMAT.DAY_DOT_MONTH_DOT_YEAR) => {
  return !!date ? format(new Date(date), formate) : "";
}