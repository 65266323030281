/*
  Application Login Page Base Layout
*/

import React, { useEffect } from 'react';

// Redux
import {
  useDispatch,
} from 'react-redux';

//Components
import Loading from "@timetrack/web/src/components/Loading";
import Error from '../Common/Alerts/Error';

// Antd
import { Row, Col } from 'antd';

// Actions
import { logout } from '@timetrack/common/src/store/actions/AuthAction';

// Logo
import logo from '../../assets/images/horizon-digital-logo-lg.png';

// Style
import './style.scss';

function AuthenticationBase({
  loading,
  children,
  error
}) {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout())
  }, [dispatch]);

  return (
    <div className="login-wrapper">
      {
        loading &&
        // true &&
        <Loading />
      }

      <Row>

        <Col md={12} xs={24}>
          <div className="form-row">
            <Row className="login-form-wrapper">
              {/*Authentication Forms with Heading*/}
              <Col md={24} xs={24}>

                {/*Error Message*/}
                <Error
                  show={!!error}
                  message={error}
                />

                {children}

              </Col>
              <p className='footerText'>© {1900 + new Date().getYear()} Sunset. All Rights Reserved.</p>
            </Row>
          </div>
        </Col>

        <Col className='login-pic-background' md={12} xs={0}>
          <figure className="login-pic">
            <img src={logo} alt="banner" />
          </figure>
        </Col>

      </Row>
    </div>
  );
}

export default AuthenticationBase;