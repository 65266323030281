import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { cleanAndCapitalize } from '@timetrack/common/src/helpers/GeneralHelper';
import "./style.scss";
import LOCALIZATION from '@timetrack/common/src/services/LocalizationService';

const MultiSelectButton = ({ id, results, preselectedSkills = [], onSelectionChange, resetSkills }) => {
    const [selectedSkills, setSelectedSkills] = useState(preselectedSkills);
    useEffect(() => {
        if (!!resetSkills)
            setSelectedSkills([])
    }, [resetSkills])

    useEffect(() => {
        if (onSelectionChange) {
            onSelectionChange(selectedSkills);
        }
    }, [selectedSkills]);

    const handleSkillClick = (positionIndex, skill) => {
        setSelectedSkills((prevSelectedSkills) => {
            const updatedSkills = [...prevSelectedSkills];
            const existingPositionIndex = updatedSkills?.findIndex(item => item?.name_display === results?.skills?.[positionIndex]?.name_display);

            if (existingPositionIndex !== -1) {
                const existingSkillIndex = updatedSkills?.[existingPositionIndex]?.sub_skills?.findIndex(item => item?.id === skill?.id);

                if (existingSkillIndex !== -1) {
                    // Skill already selected, remove it
                    updatedSkills?.[existingPositionIndex]?.sub_skills?.splice(existingSkillIndex, 1);
                    if (updatedSkills?.[existingPositionIndex]?.sub_skills?.length === 0) {
                        updatedSkills?.splice(existingPositionIndex, 1); // Remove position if no subskills are selected
                    }
                } else {
                    // Add new skill
                    updatedSkills?.[existingPositionIndex]?.sub_skills?.push(skill);
                }
            } else {
                // Add new position with selected skill
                updatedSkills?.push({
                    id: results?.skills?.[positionIndex]?.id,
                    name_display: results?.skills?.[positionIndex]?.name_display,
                    sub_skills: [skill],
                });
            }

            return updatedSkills;
        });
    };

    return (
        <div>
            {results?.skills?.map((position, index) => (
                <div key={index}>
                    <p className='title mt-5 mb-1'>{position?.name_display}</p>
                    {position?.sub_skills.length > 0 ? position?.sub_skills?.map((skill) => (
                        <Button
                            className='multiSelect'
                            size='middle'
                            key={skill?.id}
                            onClick={() => handleSkillClick(index, skill)}
                            type={selectedSkills?.some(pos => pos?.name_display === position?.name_display && pos?.sub_skills?.some(subSkill => subSkill?.id === skill?.id)) ? 'primary' : 'secondary'}
                        >
                            {cleanAndCapitalize(skill?.name_display)}
                        </Button>
                    )) : ""}
                </div>
            ))}
        </div>
    );
};

export default MultiSelectButton;
