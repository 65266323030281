import { PlusOutlined } from "@ant-design/icons";

// Antd
import { Button, Upload, Form, message } from "antd";

// Localization
import LOCALIZATION from "@timetrack/common/src/services/LocalizationService";

function ImageUpload({
    placeholder,
    label,
    action,
    // listType,
    valuePropName,
    fileList,
    notwrapInForm,
    maxCount,
    required,
    validator,
    name,
    validateTrigger,
    showUploadList,
    onChange,
    type,
    accept,
    multiple,
    beforeUpload
}) {
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };
    let rules = [{ required: required, message: LOCALIZATION.REQUIRED }];

    // Custom Validations
    !!validator && rules.push({ validator });

    const imageUpload = (
        <Upload
            placeholder={placeholder || label}
            action={action}
            fileList={fileList}
            onChange={onChange}
            type={type}
            accept={accept}
            maxCount={maxCount}
            customRequest={dummyRequest}
            // listType={listType}
            showUploadList={showUploadList}
            multiple={multiple}
            beforeUpload={beforeUpload}
        >
            <div className="upload-content">
                <p>
                    <PlusOutlined
                        style={{
                            color: "black",
                            fontSize: "15px",
                            marginBottom: "15px",
                        }}
                    />
                </p>
                <p className="upload-text">Upload documents</p>
                <p className="upload-hint">.pdf</p>
            </div>
        </Upload>
    );
    return !!notwrapInForm ? (
        <>{imageUpload}</>
    ) : (
        <Form.Item
            name={name}
            label={label}
            validateTrigger={validateTrigger || "onBlur"}
            rules={rules}
            valuePropName={valuePropName}
            getValueFromEvent={(e) => {
                if (Array.isArray(e)) {
                    return e;
                }
                return e && e.fileList;
            }}
        >
            {imageUpload}
        </Form.Item>
    );
}
export default ImageUpload;
