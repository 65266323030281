// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  font-size: 1rem;
  font-weight: 400;
  color: #1A2659;
}

.multiSelect {
  min-height: 2.1875rem !important;
  min-width: 4.375rem !important;
  margin: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/Common/FormElements/MultiSelectButton/style.scss","webpack://./src/assets/sass/_variables.scss"],"names":[],"mappings":"AAGA;EACI,eAAA;EACA,gBAAA;EACA,cCsCgB;ADxCpB;;AAKA;EACI,gCAAA;EACA,8BAAA;EACA,WAAA;AAFJ","sourcesContent":["@import \"../../../../assets/sass/variables\";\n\n\n.title {\n    font-size: rem(16);\n    font-weight: 400;\n    color: $colorMidnightBlue;\n}\n\n.multiSelect {\n    min-height: rem(35) !important;\n    min-width: rem(70) !important;\n    margin: 5px;\n}","@use \"sass:math\";\n\n$html-font-size: 16px;\n\n@function stripUnit($value) {\n    @return math.div($value, $value * 0 + 1);\n}\n\n@function rem($pxValue) {\n    @return #{math.div(stripUnit($pxValue), stripUnit($html-font-size))}rem;\n}\n\n//Color Vars\n\n$colorwhite: #fff;\n$colorblack: #000;\n$colorprimary: #110534;\n$colorsecondary: #7E1F8D;\n$colorcontent: #374254;\n$border-color: #E2E8F0;\n$field-bg: #eef0f2;\n$heading-color: #32383F;\n$colorred: #f70c0c;\n$themegray: #8898AA;\n$colorprimaryblue: #DD9510;\n$colororange: #f16932;\n$colordarkblue: #1534a3;\n$colorgreyblue: #010747;\n\n//-------------\n$colorBlue: #422AFB;\n$colorBackground: #F3F2F5;\n$colorLightGrey :#8F9CBB;\n$primaryBtnColor: #13037F;\n$greyWhite: #A09BAE;\n\n\n\n// back button color\n$backBtnColor: #E3E9F1;\n\n//Font Family\n$font-secondary: 'Share', cursive;\n\n$colorMidnightBlue: #1A2659;\n$colorCharcoal: #2D3748;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
