import React, { useState, useEffect, useCallback } from "react";

// antd
import { Button, Popover, Radio } from "antd";

// helper
import { cleanAndCapitalize } from "@timetrack/common/src/helpers/GeneralHelper";

// localization
import LOCALIZATION from "@timetrack/common/src/services/LocalizationService";

// style
import "./style.scss";

const MultiSelectButton = ({ id, results, preselectedSkills = [], onSelectionChange, resetSkills }) => {
    const [selectedSkills, setSelectedSkills] = useState(preselectedSkills);

    useEffect(() => {
        if (!!resetSkills) {
            setSelectedSkills([]);
        }
    }, [resetSkills]);

    useEffect(() => {
        if (onSelectionChange) {
            onSelectionChange(selectedSkills);
        }
    }, [selectedSkills, onSelectionChange]);

    const expertiseLevels = ["Junior", "Intermediate", "Expert"];

    const expertiseColors = {
        Junior: "#dc3545",
        Intermediate: "#ffc107",
        Expert: "#44BB8F",
    };

    const handleSkillClick = useCallback((positionIndex, skill) => {
        setSelectedSkills((prevSelectedSkills) => {
            const updatedSkills = [...prevSelectedSkills];
            const existingPositionIndex = updatedSkills?.findIndex(
                (item) => item?.name_display === results?.skills?.[positionIndex]?.name_display
            );

            if (existingPositionIndex !== -1) {
                const existingSkillIndex = updatedSkills?.[existingPositionIndex]?.sub_skills?.findIndex(
                    (item) => item?.id === skill?.id
                );

                if (existingSkillIndex !== -1) {
                    // Remove skill
                    updatedSkills?.[existingPositionIndex]?.sub_skills?.splice(existingSkillIndex, 1);
                    if (updatedSkills?.[existingPositionIndex]?.sub_skills?.length === 0) {
                        updatedSkills?.splice(existingPositionIndex, 1);
                    }
                } else {
                    // Add skill with default expertise
                    updatedSkills?.[existingPositionIndex]?.sub_skills?.push({ ...skill, expertise_level: "Junior" });
                }
            } else {
                // Add new position with skill and default expertise
                updatedSkills?.push({
                    id: results?.skills?.[positionIndex]?.id,
                    name: results?.skills?.[positionIndex]?.name,
                    name_display: results?.skills?.[positionIndex]?.name_display,
                    sub_skills: [{ ...skill, expertise_level: "Junior" }],
                });
            }

            return updatedSkills;
        });
    }, [results]);

    const handleExpertiseChange = (positionIndex, skillId, expertise_level) => {
        setSelectedSkills((prevSelectedSkills) =>
            prevSelectedSkills?.map((position) => {
                if (position?.id === results?.skills?.[positionIndex]?.id) {
                    return {
                        ...position,
                        sub_skills: position?.sub_skills?.map((subSkill) =>
                            subSkill?.id === skillId ? { ...subSkill, expertise_level } : subSkill
                        ),
                    };
                }
                return position;
            })
        );
    };

    const renderExpertisePopover = (positionIndex, skill) => (
        <div>
            <p>{LOCALIZATION.EXPERTISE_LEVEL}</p>
            <Radio.Group
                onChange={(e) => handleExpertiseChange(positionIndex, skill?.id, e?.target?.value)}
                value={
                    selectedSkills
                        ?.find((pos) => pos?.name_display === results?.skills?.[positionIndex]?.name_display)
                        ?.sub_skills?.find((subSkill) => subSkill.id === skill?.id)?.expertise_level
                }
            >
                {expertiseLevels?.map((level) => (
                    <Radio.Button key={level} value={level}>
                        {level}
                    </Radio.Button>
                ))}
            </Radio.Group>
        </div>
    );

    return (
        <div>
            {results?.skills?.map((position, index) => (
                <div key={index}>
                    <p className="title mt-5 mb-1">{position?.name_display}</p>
                    {position?.sub_skills?.length > 0 ? position?.sub_skills?.map((skill) => (
                        <Popover
                            key={skill?.id}
                            content={renderExpertisePopover(index, skill)}
                            getPopupContainer={(triggerNode) => triggerNode?.parentNode}
                        >
                            <Button
                                className="multiSelect"
                                size="middle"
                                onClick={() => handleSkillClick(index, skill)}
                                type={
                                    selectedSkills?.some(
                                        (pos) =>
                                            pos?.name_display === position?.name_display &&
                                            pos?.sub_skills?.some((subSkill) => subSkill?.id === skill?.id)
                                    )
                                        ? "primary"
                                        : "secondary"
                                }
                            >
                                {cleanAndCapitalize(skill?.name_display)}
                                {selectedSkills?.some(
                                    (pos) =>
                                        pos?.name_display === position?.name_display &&
                                        pos?.sub_skills?.some((subSkill) => subSkill?.id === skill?.id)
                                ) && (
                                        <span className="expertiseColor"
                                            style={{
                                                position: "absolute",
                                                backgroundColor: expertiseColors[
                                                    selectedSkills
                                                        ?.find((pos) => pos?.name_display === position?.name_display)
                                                        ?.sub_skills?.find((subSkill) => subSkill?.id === skill?.id)?.expertise_level
                                                ],
                                            }}
                                        >
                                            {
                                                selectedSkills
                                                    ?.find((pos) => pos?.name_display === position?.name_display)
                                                    ?.sub_skills?.find((subSkill) => subSkill?.id === skill?.id)?.expertise_level?.[0]
                                            }
                                        </span>
                                    )}
                            </Button>
                        </Popover>
                    )) : ""}
                </div>
            ))}
        </div>
    );
};

export default MultiSelectButton;
