/*
  General Response Handler
*/

import StatusCodes from '../../constants/StatusCodes';
import StorageService from '../../services/StorageService';

export function handleResponse(response) {
  if (response && response.status === StatusCodes.SUCCESS || response.status === StatusCodes.RESOURCE_UPDATED || response.status === StatusCodes.RESOURCE_CREATED) {
    return response.data;
  } else {
    handleError(response);
  }
}

export function handleError(error) {
  if (error?.response?.status === StatusCodes.UNAUTHORIZE) {
    StorageService.instance.deleteLoginData(); // clear session
    window.location.reload();
  }
  throw error;
}